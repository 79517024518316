<template>
  <div>
    <Modal :options="{width:'40vw', close: true}" @close="$emit('close')">
      <div class="title">Cesión</div>
      <div class="body">
        <div v-if="$auth.can('analyticpoint','usuario_cesion_admin')" class="row form-group">
          <div class="col-sm-12 col-lg-3">Alianza</div>
          <div class="col-sm-12 col-lg-9">
            <div class="form-control">{{ cesion.alianza.nombre }}</div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12 col-lg-3">Solicitud</div>
          <div class="col-sm-12 col-lg-9">
            <div class="form-control">{{ cesion.solicitud.solicitud_id }}</div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12 col-lg-3">Monto propuesto</div>
          <div class="col-sm-12 col-lg-9">
            <div class="form-control">${{ $helper.moneyFormat(cesion.monto_propuesto) }}</div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12 col-lg-3">Monto aceptado</div>
          <div class="col-sm-12 col-lg-9">
            <div class="form-control">${{ cesion.monto_aceptado ? $helper.moneyFormat(cesion.monto_aceptado) : '0.00' }}</div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12 col-lg-3">Observaciones</div>
          <div class="col-sm-12 col-lg-9">
            <div class="form-control">{{ cesion.observaciones ? cesion.observaciones : '--' }}</div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12 col-lg-3">Resolución</div>
          <div class="col-sm-12 col-lg-9">
            <div class="form-control">{{ cesion.resolucion ? cesion.resolucion : '--' }}</div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12 col-lg-3">Fecha resolución</div>
          <div class="col-sm-12 col-lg-9">
            <div class="form-control">{{ cesion.resolucion_at ? $moment(cesion.resolucion_at).format('dd  DD MMM YYYY - HH:mm') : '--' }}</div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12 col-lg-3">Estatus</div>
          <div class="col-sm-12 col-lg-9">
            <div class="form-control">{{ cesion.estatus }}</div>
          </div>
        </div>
      </div>
      <div class="footer text-right">
        <button v-if="$auth.can('analyticpoint','usuario_cesion_admin') && cesion.resolucion == 'rechazado' && !cesion.recesion_at" class="btn btn-success mr-2" @click="modal_cesion=true">Volver a ceder</button>
        <button class="btn btn-primary mr-2" @click="solicitud_id=cesion.solicitud_id">Ver solicitud</button>
        <button class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
      </div>
    </Modal>

    <Detalle v-if="solicitud_id" :cesion="cesion" :solicitud_id="solicitud_id" :estatus="cesion.estatus" @close="finalizar" />
    <Ceder v-if="modal_cesion" :solicitud="cesion.solicitud" :recesion="modal_cesion" @close="cerrar_modal"/>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import Detalle from './Info/Detalle.vue';
import Ceder from './../Info/Ceder.vue';

export default {
  components: {
    Modal, Detalle, Ceder
  },
  props: {
    cesion: {
      type: Object
    }
  },
  data() {
    return {
      solicitud_id: null,
      modal_cesion: false
    }
  },
  methods: {
    finalizar() {
      this.solicitud_id = null;
      this.$emit('update');
    },
    cerrar_modal() {
      this.modal_cesion = false;
      this.$emit('update',1);
    }
  }
}
</script>